.swapPart {
  margin-top: 120px;

  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  border-radius: 25px;
  height: 400px;
  padding: 10px;
  width: 650px;
  background-color: #39378b75;
}

.swapPartMobile {
  margin-top: 120px;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  border-radius: 25px;
  height: 400px;
  padding: 10px;
  background-color: #39378b75;
}

.css-give2i{
  padding: 10px 10px !important;
  margin: 4px !important;
}

.listTokens {
  cursor: pointer;
  width: 45px;
  height: 45px;
  margin: 2px;
  justify-content: center;
  display: flex;
  border-radius: 6px;
  background-color: #202531;
}
.listTokens:active {
  background-color: wheat;
}

.UnderSwapPart {
  margin-top: 120px;
  display: flex;
  margin-left: auto;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2), 10px 0 10px rgba(0, 0, 0, 0.2),
    0 -10px 10px rgba(0, 0, 0, 0.2), -10px 0 10px rgba(0, 0, 0, 0.2);
  margin-right: auto;
  border-radius: 25px;
  height: 400px;
  width: 650px;
  background-image: linear-gradient(#121137, #17163efc 110%);
}

.UnderSwapPartMobile {
  margin-top: 120px;
  display: flex;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2), 10px 0 10px rgba(0, 0, 0, 0.2),
    0 -10px 10px rgba(0, 0, 0, 0.2), -10px 0 10px rgba(0, 0, 0, 0.2);
  margin-left: 15px;
  margin-right: 15px;
  border-radius: 25px;
  height: 400px;
  background-image: linear-gradient(#121137, #17163efc 110%);
}
.tokensInputZone {
  display: flex;
  flex-direction: row;
  padding: 10px;
  height: 25%;
  border-radius: 15px;
  background-color: #38378b;
}

.amountInDiv {
  width: 210%;
}

.spendText {
  color: rgb(156 155 225 / 74%);
  font-weight: 600;
  text-align: left;
}
.buttonPart {
  width: 40%;
  display: flex;
}

.inputAmountToSwap {
  width: 100%;
  background-color: #38378b;
  border: 0px;
  color: white;
  font-size: 50px;
  font-weight: 600;
  appearance: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.inputAmountToSwap:focus-visible {
  border: 0px !important;
}

.logoToken {
  width: 40px;
}

.tokenSelectedDiv {
  display: flex;
}

.tokenSelectedButton {
  border-radius: 15px;
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
}

.textTokenIn {
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  font-weight: 500;
}

.spaceZoneBetween {
  height: 40px;
}

.inverseImage {
  width: 40px;
  cursor: pointer;
}

.balanceIn {
  display: flex;
  margin-top: -13px;
  color: grey;
  white-space: pre;
}
.textBalanceIn {
  width: 90%;
  display: flex;
  margin-right: auto;
}

.maxTextBalance {
  text-decoration: underline;
  margin-right: 20px;
  cursor: pointer;
}

.swapButton {
  background: #fbca1f;
  font-family: inherit;
  padding: 0.6em 1.3em;
  margin-top: 20px;
  width: 100%;
  height: 70px;
  font-weight: 900;
  font-size: 18px;
  border: 3px solid rgb(0, 3, 41);
  border-radius: 0.4em;
  box-shadow: 0.1em 0.1em;
  cursor: pointer;
}

.swapButton:hover {
  transform: translate(-0.05em, -0.05em);
  box-shadow: 0.15em 0.15em;
}

.swapButton:active {
  transform: translate(0.05em, 0.05em);
  box-shadow: 0.05em 0.05em;
}

.disabledSwapButton {
  background: #92791f;
  font-family: inherit;
  padding: 0.6em 1.3em;
  margin-top: 20px;
  width: 100%;
  height: 70px;
  font-weight: 900;
  font-size: 18px;
  border: 3px solid rgb(0, 3, 41);
  border-radius: 0.4em;
  box-shadow: 0.1em 0.1em;
}

.swapTitle {
  color: #fcbf49;
  text-align: center;
}

.swapDiv {
  height: 100%;
}
.boxSwap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(18, 21, 28);
  box-shadow: 0px 0px 3px 3px rgba(255, 217, 0, 0.2);
  padding: 15px;
  border-radius: 15px;
}

.swapZone {
  display: flex;
  width: 400px;
  justify-content: space-between;
}

.imageSingleTokenPath {
  width: 50px;
  display: flex;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
}

.swapPathDiv {
  width: 160px;
  border: 3px solid rgb(26, 26, 26);
  border-radius: 20px;
}

.tokenInPath {
  text-align: center;
  color: white;
}

.amountOutPath {
  text-align: center;
  color: white;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.spaceInBetweenSingle {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
}

.toTextInMiddle {
  font-size: 30px;
}

.approveButton {
  background: #af8f19;
  font-family: inherit;
  margin-top: 20px;
  width: 100%;
  height: 30px;
  font-weight: 900;
  font-size: 18px;
  border: 3px solid rgb(0, 3, 41);
  border-radius: 0.4em;
  box-shadow: 0.1em 0.1em;
  cursor: pointer;
}

.approveButton:hover {
  transform: translate(-0.05em, -0.05em);
  background: #cca61e;
  box-shadow: 0.15em 0.15em;
}

.approveButton:active {
  transform: translate(0.05em, 0.05em);
  box-shadow: 0.05em 0.05em;
}

.finalSwapButton {
  background: #fbca1f;
  font-family: inherit;
  padding: 0.5em 1.1em;
  margin-top: 2px;
  width: 100%;
  height: 50px;
  font-weight: 900;
  font-size: 18px;
  border: 3px solid rgb(0, 3, 41);
  border-radius: 0.4em;
  box-shadow: 0.1em 0.1em;
  cursor: pointer;
}

.finalSwapButton:hover {
  transform: translate(-0.05em, -0.05em);
  box-shadow: 0.15em 0.15em;
}

.finalSwapButton:active {
  transform: translate(0.05em, 0.05em);
  box-shadow: 0.05em 0.05em;
}
.approveButtonApproved {
  background: #af8f19;
  font-family: inherit;
  margin-top: 20px;
  width: 100%;
  height: 30px;
  font-weight: 900;
  font-size: 18px;
  border: 3px solid rgb(0, 3, 41);
  border-radius: 0.4em;
  box-shadow: 0.1em 0.1em;
  cursor: pointer;
}

.boxTwoSwap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(18, 21, 28);
  box-shadow: 0px 0px 3px 3px rgba(255, 217, 0, 0.2);
  padding: 15px;
  border-radius: 15px;
}

.boxTwoSwapMobile {
  position: absolute;
  top: 500px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(18, 21, 28);
  box-shadow: 0px 0px 3px 3px rgba(255, 217, 0, 0.2);
  padding: 15px;
  border-radius: 15px;
}
.approveTwoButton {
  background: #af8f19;
  font-family: inherit;
  margin-top: 20px;
  margin-left: auto;
  display: flex;
  margin-right: auto;
  width: 90%;
  height: 30px;
  font-weight: 900;
  font-size: 18px;
  border: 3px solid rgb(0, 3, 41);
  border-radius: 0.4em;
  box-shadow: 0.1em 0.1em;
  cursor: pointer;
}

.approveTwoButton:hover {
  transform: translate(-0.05em, -0.05em);
  background: #cca61e;
  box-shadow: 0.15em 0.15em;
}

.approveTwoButton:active {
  transform: translate(0.05em, 0.05em);
  box-shadow: 0.05em 0.05em;
}

.finalTwoSwapButtonRight {
  background: #fbca1f;
  font-family: inherit;
  padding: 0.5em 1.1em;
  margin-top: 2px;
  margin-left: -29px;
  display: flex;
  width: 110%;
  height: 50px;
  font-weight: 900;
  font-size: 18px;
  border: 3px solid rgb(0, 3, 41);
  border-radius: 0.4em;
  box-shadow: 0.1em 0.1em;
  cursor: pointer;
}
.finalTwoSwapButton {
  background: #fbca1f;
  font-family: inherit;
  padding: 0.5em 1.1em;
  margin-top: 2px;
  margin-left: 9px;
  display: flex;
  width: 110%;
  height: 50px;
  font-weight: 900;
  font-size: 18px;
  border: 3px solid rgb(0, 3, 41);
  border-radius: 0.4em;
  box-shadow: 0.1em 0.1em;
  cursor: pointer;
}
.finalTwoSwapButton:hover {
  transform: translate(-0.05em, -0.05em);
  box-shadow: 0.15em 0.15em;
}

.finalTwoSwapButton:active {
  transform: translate(0.05em, 0.05em);
  box-shadow: 0.05em 0.05em;
}

.finalTwoSwapButtonRight:hover {
  transform: translate(-0.05em, -0.05em);
  box-shadow: 0.15em 0.15em;
}

.finalTwoSwapButtonRight:active {
  transform: translate(0.05em, 0.05em);
  box-shadow: 0.05em 0.05em;
}

.swapContainer {
  width: 42%;
}

.numberOneTwoOrder {
  position: absolute;
  margin-top: 20px;
  color: #292d02;
}
.numberOneOrder {
  position: absolute;
  margin-top: 20px;
  margin-left: -218px;
  color: #292d02;
}

.partNumber {
  position: absolute;
  margin-top: -70px;
  margin-left: 332px;
}

.partInMiddlePool {
  border-radius: 10px;
  width: 35px;
  height: 35px;
  position: absolute;
  margin-top: 35px;
}
.logoPlatform {
  margin-left: 1px;
  width: 35px;
}

.showSelectedPlatform {
  color: wheat;
  font-size: 14px;
  display: flex;
  justify-content: center;
}
.swappedText {
  color: #fcbf49;
}

.uppdarZoneSwap {
  display: flex;
}
.countdownPart {
  display: flex;
  margin-left: auto;
  margin-top: -5px;
}
