.chainSelect {
  color: rgba(255, 255, 255, 0.692);
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-shadow: 0 0 20px rgba(255, 255, 255, 0.397);
  background: #39378b75;
  overflow: hidden;
  font-size: 0.9rem;
  font-weight: 600;
  gap: 8px;
  height: 100%;
  border-radius: 5px;
  margin: 0 5px;
  transition: 0.2s;
  border: 1px solid transparent;
}

.chainSelect:hover {
  cursor: pointer;
  border-color: rgba(255, 255, 255, 0.623);
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.144),
    rgba(255, 255, 255, 0.247),
    rgba(255, 255, 255, 0.39)
  );
  box-shadow: 0 6px rgba(255, 255, 255, 0.623);
  transform: translateY(-6px);
}

.chainSelect:active {
  transform: translateY(2px);
  box-shadow: none;
}

.reward-btn {
  width: 120px;
  height: 40px;
  background-color: #39378b75;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  transition: all 0.3s;
}
.IconContainer {
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.IconContainer svg {
  width: 40%;
  z-index: 3;
}
.box-top {
  transition: all 0.3s;
}
.text {
  width: 70px;
  height: 100%;
  font-size: 13px;
  color: #6a8ef6;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 600;
}
.reward-btn:hover .IconContainer .box-top {
  transform: translateY(-5px);
}
.reward-btn:hover {
  background-color: #4846b175;
}
.reward-btn:hover .coin {
  transform: translateY(-5px);
  transition-delay: 0.2s;
}
.coin {
  width: 25%;
  height: 25%;
  background-color: #e4d61a;
  position: absolute;
  border-radius: 50%;
  transition: all 0.3s;
  z-index: 1;
  border: 2px solid #ffe956;
  margin-top: 4px;
}

.chainImage {
  position: relative;
  width: 30px;
}

.boxChainSelect {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400;
  background-color: rgb(18, 21, 28);
  box-shadow: 0px 0px 3px 3px rgba(255, 217, 0, 0.2);
  padding: 15px;
  height: 110px;
  border-radius: 15px;
}

.inputChain {
  background-color: rgb(35 37 41);
  border: 0px;
  border-radius: 4px;
  padding: 1px;
  height: 25px;
  color: white;
}

.inputChain:focus {
  border: 0px;
}

.listChains {
  cursor: pointer;
  width: 45px;
  height: 45px;
  justify-content: center;
  display: flex;
  border-radius: 6px;
  background-color: #202531;
}
.listChains:active {
  background-color: wheat;
}

.chain {
  padding: 2px;
  height: 100%;
  width: 100%;
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
}
.mainChainList {
  display: flex;
  gap: 10px;
  padding: 10px;
}

.divAirdrop {
  position: relative;
  bottom: -5px;
}

.headerAirdrop {
  color: #fcbf49;
  display: flex;
  text-align: center;
}
.textAidropUtopia {
  color: wheat;
  padding: 10px;
  text-align: center;
}

.boxAirdrop {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 800px;
  transform: translate(-50%, -50%);
  width: 400;
  background-color: rgb(18, 21, 28);
  box-shadow: 0px 0px 3px 3px rgba(255, 217, 0, 0.2);
  padding: 15px;
  border-radius: 15px;
}

.boxAirdropMobile {
  position: absolute;
  margin-top: 600px;
  left:50%;
  width: 95%;
  transform: translate(-50%, -50%);
  background-color: rgb(18, 21, 28);
  box-shadow: 0px 0px 3px 3px rgba(255, 217, 0, 0.2);
  padding: 15px;
  border-radius: 15px;
}

.eligibleText{
  color:#fcbf49;
  text-align: center
}

.numbersCountdown{
  font-weight: 600;
  color:#fcbf49;
}

.mainCountdown{
  text-align: center;
  color:wheat;
}
.textBottomAirdrop{
  color: wheat;
  margin-top: -12px;
  text-align: center;
}

.headerAirdropPart{
  display: flex;
  justify-content: center;
  gap:20px;
}

.logoUtopia {
  width:50px;
  height: 50px;
}

.customAirdropBalanceMain{
  background-color: #39378b75;
  display: flex;
  height: 40px;
  border-radius: 0px 8px 8px 0px;
  position: relative;
  bottom: -5px;
  border-left:1px solid #4a48b475;
}

.customAirdropBalanceDiv{
  display: flex;
  gap: 5px;
}

.logoUtopiaAirdrop{
  width:30px;
  height: 30px;
  margin-top: auto;
  margin-left: 5px;
  margin-bottom: auto;
}

.textBalanceAirdrop{
  color:#fcbf49;
  font-weight: 400;
  margin:auto 5px auto 1px;
}