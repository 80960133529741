.desktop-header {
    padding: 10px;
    display: flex;
    min-height: 30px;
    justify-content: space-around;
    align-items: center;
  }

.mainLogo{
  width: 100%;
  max-width: 250px;
}
.main-header {
  display: flex;
  justify-content: space-between;
  border-radius: 20px;
  color: black;
  padding: 10px 10px;
  gap: 100px;
  margin:auto;
}

.headerOption{
  margin-bottom: 20px;
  font-size: 10px;
  color: #fcbf49;
  text-decoration: none;
}

.accountHeader{
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  gap:8px;
}

.accountText{
  margin-bottom: auto;
  color: #fcbf49;
}

.blockies{
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  max-width: 30px;
  max-height: 30px;
  border-radius: 10px;
}

.loginButton {
  background: #fbca1f;
  font-family: inherit;
  padding: 0.6em 1.3em;
  height: 100%;
  font-weight: 900;
  font-size: 18px;
  border: 3px solid rgb(0, 3, 41);
  border-radius: 0.4em;
  box-shadow: 0.1em 0.1em;
  cursor: pointer;
}

.loginButton:hover {
  transform: translate(-0.05em, -0.05em);
  box-shadow: 0.15em 0.15em;
}

.loginButton:active {
  transform: translate(0.05em, 0.05em);
  box-shadow: 0.05em 0.05em;
}


.desktopFadedHeader{
  height: 160px;
  background-image: linear-gradient(#121137, #17163efc 110%);
}

.profileButton {
  color: rgba(255, 255, 255, 0.692);
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-shadow: 0 0 20px rgba(255, 255, 255, 0.397);
  background: #39378b75;
  overflow: hidden;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  gap: 8px;
  border-radius: 5px;
  height: 100%;
  margin: 0 5px 0 0;
  transition: 0.2s;
  border: 1px solid transparent;
}

.profileButton:hover {
  cursor: pointer;
  border-color: rgba(255, 255, 255, 0.623);
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.144),
    rgba(255, 255, 255, 0.247),
    rgba(255, 255, 255, 0.39)
  );
  box-shadow: 0 6px rgba(255, 255, 255, 0.623);
  transform: translateY(-6px);
}

.profileButton:active {
  transform: translateY(2px);
  box-shadow: none;
}

.zoneUpTop{
  margin-left: auto;
  display: flex;
  gap:10px;
  height:50px
}
